import React, { useState } from "react";
import { useTable } from "react-table";
import { Grid } from "@mui/material";

const TableNumberInput = (props) => {
    const { column, row, cell, updateData } = props;
    const onChange = (e) => updateData(row.index, column.id, e.target.value);
    return <input type="number" value={cell.value} onChange={onChange}/>;
}

const TableNameInput = (props) => {
    const { column, row, cell, updateData } = props;
    const onChange = (e) => updateData(row.index, column.id, e.target.value);
    return <input type="text" value={cell.value} onChange={onChange}/>;
}

const Form = ( props ) => {
    const id = Math.round(Math.random() * 100000).toString()
    const calcAnx = (ave, max) => {        
        var r =  ((max - ave) * (max - ave)).toFixed(1)
        return r
    }   
    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: TableNameInput,
           },
            {
                Header: 'Ave (sp)',
                accessor: 'ave',
                Cell: TableNumberInput,
            },
            {
                Header: 'Max (sp)',
                accessor: 'max',
                Cell: TableNumberInput,
            },
            {
                Header: 'Anx (sp)',
                accessor: (v) => calcAnx(v.ave, v.max),
            },
        ],
        []
    )
    const initialData = React.useMemo(
        () => [
            {
                name: 'task1',
                ave: '2.0',
                max: '5.0'
            },
            {
                name: 'task2',
                ave: '3.0',
                max: '5.0'
            },
            {
                name: 'task3',
                ave: '2.0',
                max: '2.0'
            }
        ],
        []
    )
    const [data, setData] = useState(initialData);
    const updateData = (rowIndex, columnID, value) => {
        setData((oldData) =>
          oldData.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...oldData[rowIndex],
                [columnID]: value
              };
            }
            return row;
          })
        );
    };
    const addData = () => {
        setData((oldData) => oldData.concat([{name: '', ave: '0.0', max: '0.0'}]))
    }
    const deleteData = () => {       
        setData((oldData) => oldData.slice(0, oldData.length - 1))
    }

    const calcEstimation = () => {
        var sumAve = data.map(v => parseFloat(v.ave)).reduce((prev, current) => prev + current, 0);
        var sumMax = data.map(v => parseFloat(v.max)).reduce((prev, current) => prev + current, 0);
        var sumAnx = data.map(v => parseFloat(calcAnx(parseFloat(v.ave), parseFloat(v.max)))).reduce((prev, current) => prev + current, 0);
        var buffer = Math.sqrt(sumAnx)
        
        //console.log('sum of average', sumAve)
        //console.log('sum of max', sumMax)
        //console.log('sum of anx', sumAnx)
        //console.log('buffer', buffer) now.getFullYear().toString() + now.getMonth().toString()
        
        return Math.round((parseFloat(sumAve) + buffer) * 10) / 10
    }

    const getNowString = () => {
        const date = new Date();
        let n = date.getFullYear() * 10000;
        n += date.getMonth() * 100 + 100;
        n += date.getDate();
        return ("000" + n).slice(-8);
    }
    
    const handleExport = (e) => {
        e.preventDefault()
        const serialized = JSON.stringify(data)        
        const blob = new Blob([serialized], { type: 'text/json' })
        const a = document.createElement('a')
        a.download = 'mitsumori-' + getNowString() + '-' + id + '.json'
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const handleImport = (e) => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            var parsed = JSON.parse(e.target.result)
            // console.log("parsed", parsed);
            setData((oldData) => parsed)
        }
    }
    
    const tableInstance = useTable({ columns, data, updateData })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance    
    return (
        <Grid container spacing={3}>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
                <item>
                <div>
                    <h1>Mitsumori</h1>
                    <h2>Task List</h2>
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (<th {...column.getHeaderProps()}> {column.render('Header')} </th>))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <button type="button" onClick={addData}>
                        Add
                    </button>
                    <button type="button" onClick={deleteData}>
                        Delete
                    </button>
                    <h4>Import</h4>
                    <input type="file" onChange={handleImport} />
                    <h4>Export</h4>
                    <button type="button" onClick={handleExport}>
                        Export
                    </button>
                    <div>
                        <br></br>
                        <h1><b><i>{calcEstimation()} days</i></b></h1>
                    </div>
                </div>
                </item>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    );
}

// https://codesandbox.io/s/qjwmf?file=/src/index.js

export default Form;
